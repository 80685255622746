import React from 'react';

import { Geolocation } from '../Geolocation';

import './Root.css';

export function Root() {
  return (
    <div className="App">
      <Geolocation />
    </div>
  );
}
