import React from 'react';

import { useGeolocation } from '../hooks/useGeolocation';

export function Geolocation() {
  const [loading, location, requestLocation] = useGeolocation();

  return (
    <>
      Current position: <pre>{JSON.stringify(location)}</pre>
      {loading ? 'Loading...' : <button onClick={requestLocation}>Request</button>}
    </>
  );
}
