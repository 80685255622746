import { useState } from 'react';
import geolocator from 'geolocator';

export function useGeolocation() {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const requestLocation = () => {
    setLoading(true);

    geolocator.locate(undefined, (err, location) => {
      if (err) {
        console.error(err);
      }

      setLoading(false);
      setLocation(location);
    });
  };

  return [loading, location, requestLocation];
}

/*
function useFriendStatus(friendID) {
  const [isOnline, setIsOnline] = useState(null);

  useEffect(() => {
    function handleStatusChange(status) {
      setIsOnline(status.isOnline);
    }

    ChatAPI.subscribeToFriendStatus(friendID, handleStatusChange);
    return () => {
      ChatAPI.unsubscribeFromFriendStatus(friendID, handleStatusChange);
    };
  });

  return isOnline;
}
*/
